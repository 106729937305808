.sidebar .navbar-brand .logo-title h4, .sidebar .navbar-brand .logo-title .h4 {
  display: table-cell;
  vertical-align: middle;
  font-size: 16px;
}
.theme-color-pink-img {
  display:block;
}
.theme-color-default-img {
  display:none;
}
.wedding-subnav{
  border-bottom: 1px solid #f4f1f7;
  margin-bottom: 20px;
  justify-content: space-evenly;
  li > a{
    color:#373737;
  }
  li > a.active{
    color:$primary;
    font-weight:500;
 }
}
table tr.row-gender-1 td{
  color: $blue;
}

table tr.row-gender-2 td{
  color: $pink;
}
legend{
  font-size:16px;
}
.weddingActorCard{
  .photo{
    border:1px solid lightgray;
  }
  span{
    display:block;
    &.name{
      font-weight:bold;
      font-size:18px;
    }
  }
}
