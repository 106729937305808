
// Card deck

.card-deck {
  display: flex;
  flex-direction: column;

  .card {
    margin-bottom: $spacer;
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    margin-right: -$spacer;
    margin-left: -$spacer;

    .card {
      display: flex;
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: $spacer;
      margin-bottom: 0; // Override the default
      margin-left: $spacer;
    }
  }
}

// Custom JCR

.orderHeaderInfos{
  .card-header{
    text-transform: uppercase;
    color:black;
    font-weight:bold;
    font-size:20px;
    border-bottom:1px solid #dedede;
    padding-bottom:10px;
  }
}
