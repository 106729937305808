.ordersMobilePage{
  h5.orderHour{
    margin-right:5px;
    font-weight:bold;
    text-align:right;
  }
  .list-group-item.active{
    background-color:inherit;
    border-top:1px solid rgba(0, 0, 0, 0.125);
    border-right:1px solid rgba(0, 0, 0, 0.125);
    border-bottom:1px solid rgba(0, 0, 0, 0.125);
    color:inherit;
  }
  .card-header{
    text-align:center;
  }
  > .col {
    padding:0;
    margin:0;
  }
  .card-body{
    padding:5px;
  }
  .dateTitle{
    background-color:whitesmoke;
    padding:10px 0;
    margin: 10px 0;
  }
  .lastname{
    text-transform: uppercase;
  }
  .orderstatus-processing{
  }
  .orderstatus-completed{
    background-color:#eeeeee;
  }
  .delivery-Retrait{
    border-left:7px solid orange;
  }
  .delivery-Livraison{
    border-left:7px solid #0d6efd;
  }
  .delivery-Cuisson-sur-place{
    border-left:7px solid purple;
  }
  .address1 {
    margin-top: 5px;
  }
  .address{
    display:block;
  }
}

.totalsolde{
  background-color:#ffff98;
}

@include media-breakpoint-down(sm) {
  .sidebar + .main-content .iq-navbar-header .iq-container {
    padding: 0;
  }
  .sub-header-small {
    height:10px;
  }
  .navbar-light .navbar-toggler {
    display:none;
  }
  .navbar-brand img{
    display:none;
  }
  .nav .navbar-brand .logo-title{
    margin-left:0;
  }
  #navbarSupportedContent{
    display:none;
  }

}
button{
  text-transform: none !important;
}
a.btn{
  text-transform: none !important;
}
