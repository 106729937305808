.table {
    thead {
        white-space: nowrap;
        tr {
            background-color: $body-bg;
            th {
                padding: $table-th-padding-y $table-th-padding-x;
                text-transform: uppercase;
                letter-spacing: $table-th-letter-spacing;
                border-bottom: 2px solid transparent !important;
            }
        }
    }
    tbody {
        tr {
            td {
               color: $table-td-color;
               vertical-align: middle;
            }
        }
    }
    &.table-dark{
        tbody {
            tr {
                td {
                   color: $body-bg;
                   vertical-align: middle;
                }
            }
        }
    }
    overflow: hidden;
}
.table > :not(:last-child) > :last-child > * {
    border-bottom-color: transparent
}

.table-responsive{
    .dataTables_wrapper{
        .row{
            .dataTables_length{
                padding-left: $spacer * 1.5;
            }
            .dataTables_filter, .dataTables_paginate{
                padding-right: $spacer * 1.5;
            }
            .dataTables_info{
                padding-left: $spacer * 1.5;
                padding-top: unset;
            }
        }
    }
}

.table-dark{
    td {
        color: $body-bg !important;
        vertical-align: middle;
    }
}

thead, tbody, tfoot, tr, td, th{
    white-space: nowrap;
}

/* Modifications 08-2022 */

.react-bootstrap-table{
    // scrollbar on top of the table see https://stackoverflow.com/a/63507769

    transform: rotateX(180deg);
    overflow-x: auto;
    table {
        table-layout: auto !important;
        transform: rotateX(180deg);
    }
}

.nbpartsWrapper{
    display:flex;
    flex-direction:column;

    span {
        flex: 1;
        background-color:#487BFF;
        color: white;
        font-weight:bold;
        width:25px;
        height:20px;
        text-align: center;
        border-radius: 5px;
        margin-bottom: 2px;

        &.nb0, &.nbnull, &.nbundefined{
            background-color: #C4C4C4;
        }
    }
}

.table > :not(caption) > * > * {
    padding:1em 0.5em !important;
}
table label[for="date-filter-comparator-order_delivery_date"]{
    display:none;
}
table input[type="date"]{
    width: 140px;
}